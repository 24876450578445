/*
 * 업무구분: 영업
 * 화 면 명: MSPFS310P
 * 화면설명: 펀드정보_P SY000100P
 * 작 성 일: 2023.05.04
 * 작 성 자: 이태흥
 */
<template>

    <ur-page-container class="fts" :show-title="false" title="펀드정보" :topButton="true" v-if="lv_isMain">

      <!-- header start -->
      <fs-header
        ref="fsHeader"
        :propObj="pHeaderObj"
      ></fs-header>
      <!-- header end -->
      <!-- .fts-main start -->
      <div class="fts-main">
        <ur-box-container direction="column" alignV="start">
          <div class="input-top">
              <div class="column w100p gap12">
                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드상태 </label>
                      <div class="wrap-input w228">
                        <mo-radio v-model="lv_bfcer_fnd_stat_cd.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_bfcer_fnd_stat_cd.value" value="1">정상</mo-radio>
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 모집식펀드 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_fund_coll_yn.value" value="ALL">전체</mo-radio>
                        <mo-radio v-model="lv_fund_coll_yn.value" value="Y">모집중</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드유형 </label>
                      <div class="wrap-input">
                        <mo-dropdown 
                          :items="lv_commCode.code107" 
                          v-model="lv_fnd_ivt_obj_typ_cd.value" 
                          class="w228"                          
                          ref="dropdown1"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 판매사이동가능여부 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="ALL">전체</mo-radio>
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> KRZ 펀드코드 </label>
                      <div class="wrap-input ">
                        <mo-text-field v-model="lv_ksd_fnd_cd.value" class="w228" />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 신규가능여부 </label>
                      <div class="wrap-input ">
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> CDSC 모펀드 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_cdsc_cnvr_grp_cd.value" class="w228" />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 매입가능여부 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 협회표준코드 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_asso_clsf_cd.value" class="w228" />
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드명 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_bfcer_fnd_nm.value" class="w100p" />
                      </div>
                    </div>
                  </div>
                  <div class="right w61">
                    <div class="wrap-button row">
                      <mo-button primary class="btn-inquiry" @click="fn_FundListSearch()"> 조회 </mo-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="right-bg-white">
              <div class="wrap-table mt16 x-scroll h-scroll" ref="tableList">
                <table class="table row-type mb0">
                  <thead>
                    <tr>
                      <th class="w40"> </th>
                      <th> 펀드코드 </th>
                      <th> 펀드등급 </th>
                      <th> 펀드명 </th>
                      <th> 신규 가능여부 </th>
                      <th> 매입 가능여부 </th>
                      <th> 임의식 </th>
                      <th> 거치식 </th>
                      <th> 적립식 </th>
                      <th> 모집식 </th>
                      <th> 펀드전환 </th>
                      <th> 추천펀드 </th>
                      <th> 펀드구분 </th>
                      <th> 결산일 </th>
                      <th> CDSC 모펀드코드 </th>
                      <th> 클래스구분 </th>
                      <th> 이동가능 </th>
                      <th> KRZ펀드코드 </th>
                      <th> 협회분류코드 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, idx) in lv_fundShowList" :key="idx" :class="{'checked': lv_isFund == String(idx+1)}" @click="lv_isFund = String(idx+1)">
                      <td>
                        <mo-radio v-model="lv_isFund" :value="`${String(idx+1)}`"></mo-radio>
                      </td>
                      <td> {{row.bfcer_fnd_cd}} </td>
                      <td> {{row.fnd_grd_nm}} </td>
                      <td class="mw300 aL wsN"> {{row.bfcer_fnd_nm}} </td>
                      <td> {{row.new_dl_psb_yn}} </td>
                      <td> {{row.fund_rctm_dl_psb_yn}} </td>
                      <td> {{row.vltf_dl_psb_yn}} </td>
                      <td> {{row.dfrmt_dl_psb_yn}} </td>
                      <td> {{row.instl_dl_psb_yn}} </td>
                      <td> {{row.coll_poss_yn}} </td>
                      <td> {{row.fnd_cnpo_yn}} </td>
                      <td> {{row.recm_fnd_yn}}</td>
                      <td> {{row.fnd_ivt_typ_cd}} </td>
                      <td class="wsN"> {{row.nxtm_stact_ymd}} </td>
                      <td> {{row.cdsc_cnvr_grp_cd}} </td>
                      <td> {{row.cdsc_class_sc_cd_nm}} </td>
                      <td> {{row.sale_co_mov_psb_yn}} </td>
                      <td> {{row.ksd_fnd_cd}} </td>
                      <td> {{row.asso_clsf_cd}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>            
            </div>

            <!-- Pagination -->
            <div class="wrap-pagination mt-3">
              <span class="float-left">
                {{ $bizUtil.numberWithCommas(lv_page.totalItems) }} 건
              </span>
              <mo-pagination v-if="lv_page.totalItems > 0"
                :pagination="lv_page"
                :page="lv_page.currentPage"
                @move="fn_PageChange"
              />
            </div>
            <!-- //Pagination -->

            <template slot="action">
              <div class="wrap-button button-page-bottom row">
                <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
                <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
              </div>
            </template>

            <!-- alert modal popup -->
            <fs-alert-popup 
              ref="alertPopup"
              :popupObj="pAlertPopupObj"
            ></fs-alert-popup>
        </ur-box-container>
      </div>
      <!-- .fts-main end -->
    </ur-page-container>
    <ur-box-container v-else-if="!lv_isMain">
      <mo-modal class="fts-modal large" ref="modal" title="펀드정보">
        <template>
          <div class="wrap-modalcontents">
            <div class="input-top">
              <div class="column w100p gap12">
                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드상태 </label>
                      <div class="wrap-input w228">
                        <mo-radio v-model="lv_bfcer_fnd_stat_cd.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_bfcer_fnd_stat_cd.value" value="1">정상</mo-radio>
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 모집식펀드 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_fund_coll_yn.value" value="ALL">전체</mo-radio>
                        <mo-radio v-model="lv_fund_coll_yn.value" value="Y">모집중</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드유형 </label>
                      <div class="wrap-input">
                        <mo-dropdown 
                          :items="lv_commCode.code107" 
                          v-model="lv_fnd_ivt_obj_typ_cd.value" 
                          class="w228"
                          ref="dropdown1"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 판매사이동가능여부 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="ALL">전체</mo-radio>
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_sale_co_mov_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> KRZ 펀드코드 </label>
                      <div class="wrap-input ">
                        <mo-text-field v-model="lv_ksd_fnd_cd.value" class="w228" />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 신규가능여부 </label>
                      <div class="wrap-input ">
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_new_dl_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> CDSC 모펀드 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_cdsc_cnvr_grp_cd.value" class="w228" />
                      </div>
                    </div>
                    <div class="row">
                      <label class="w116"> 매입가능여부 </label>
                      <div class="wrap-input">
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="ALL" :disabled="true">전체</mo-radio>
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="Y">Y</mo-radio>
                        <mo-radio v-model="lv_rctm_dl_psb_yn.value" value="N">N</mo-radio>
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 협회표준코드 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_asso_clsf_cd.value" class="w228" />
                      </div>
                    </div>
                  </div>
                  <div class="right w61"></div>
                </div>

                <div class="row w100p gap28">
                  <div class="left mr0 gap40 w100p">
                    <div class="row">
                      <label class="w80"> 펀드명 </label>
                      <div class="wrap-input">
                        <mo-text-field v-model="lv_bfcer_fnd_nm.value" class="w100p" />
                      </div>
                    </div>
                  </div>
                  <div class="right w61">
                    <div class="wrap-button row">
                      <mo-button primary class="btn-inquiry" @click="fn_FundListSearch()"> 조회 </mo-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="right-bg-white">
              <div class="wrap-table mt16 x-scroll h-scroll" ref="tableList">
                <table class="table row-type mb0">
                  <thead>
                    <tr>
                      <th class="w40"> </th>
                      <th> 펀드코드 </th>
                      <th> 펀드등급 </th>
                      <th> 펀드명 </th>
                      <th> 신규 가능여부 </th>
                      <th> 매입 가능여부 </th>
                      <th> 임의식 </th>
                      <th> 거치식 </th>
                      <th> 적립식 </th>
                      <th> 모집식 </th>
                      <th> 펀드전환 </th>
                      <th> 추천펀드 </th>
                      <th> 펀드구분 </th>
                      <th> 결산일 </th>
                      <th> CDSC 모펀드코드 </th>
                      <th> 클래스구분 </th>
                      <th> 이동가능 </th>
                      <th> KRZ펀드코드 </th>
                      <th> 협회분류코드 </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, idx) in lv_fundShowList" :key="idx" :class="{'checked': lv_isFund == String(idx+1)}" @click="lv_isFund = String(idx+1)">
                      <td>
                        <mo-radio v-model="lv_isFund" :value="`${String(idx+1)}`"></mo-radio>
                      </td>
                      <td> {{row.bfcer_fnd_cd}} </td>
                      <td> {{row.fnd_grd_nm}} </td>
                      <td class="mw300 aL wsN"> {{row.bfcer_fnd_nm}} </td>
                      <td> {{row.new_dl_psb_yn}} </td>
                      <td> {{row.fund_rctm_dl_psb_yn}} </td>
                      <td> {{row.vltf_dl_psb_yn}} </td>
                      <td> {{row.dfrmt_dl_psb_yn}} </td>
                      <td> {{row.instl_dl_psb_yn}} </td>
                      <td> {{row.coll_poss_yn}} </td>
                      <td> {{row.fnd_cnpo_yn}} </td>
                      <td> {{row.recm_fnd_yn}}</td>
                      <td> {{row.fnd_ivt_typ_cd}} </td>
                      <td class="wsN"> {{row.nxtm_stact_ymd}} </td>
                      <td> {{row.cdsc_cnvr_grp_cd}} </td>
                      <td> {{row.cdsc_class_sc_cd_nm}} </td>
                      <td> {{row.sale_co_mov_psb_yn}} </td>
                      <td> {{row.ksd_fnd_cd}} </td>
                      <td> {{row.asso_clsf_cd}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>            
            </div>

            <!-- Pagination -->
            <div class="wrap-pagination mt-3">
              <span class="float-left">
                {{ $bizUtil.numberWithCommas(lv_page.totalItems) }} 건
              </span>
              <mo-pagination v-if="lv_page.totalItems > 0"
                :pagination="lv_page"
                :page="lv_page.currentPage"
                @move="fn_PageChange"
              />
            </div>
            <!-- //Pagination -->

          </div>
        </template>
        <template slot="action">
          <div class="wrap-button button-page-bottom row">
            <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
            <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
          </div>
        </template>

        <!-- alert modal popup -->
        <fs-alert-popup 
          ref="alertPopup"
          :popupObj="pAlertPopupObj"
        ></fs-alert-popup>

      </mo-modal>
    </ur-box-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
  import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)

  import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS310P",
    screenId: "MSPFS310P",
    components: {
      'fs-header': FSHeader,
      'fs-alert-popup': FSAlertPopup,
    },
    props: {
        popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {          
          title: '펀드정보',
        },

        // 공통 객체
        pAlertPopupObj: {},
        lv_commCode: {},
        pCustInfoObj: {},

        // 검색조건
        lv_bfcer_fnd_stat_cd: {},       // 펀드상태
        lv_fund_coll_yn: {},            // 모집식펀드

        lv_fnd_ivt_obj_typ_cd: {},      // 펀드유형
        lv_sale_co_mov_psb_yn: {},      // 판매사이동가능여부

        lv_ksd_fnd_cd: {},              // KRZ 펀드코드
        lv_new_dl_psb_yn: {},           // 신규가능여부

        lv_cdsc_cnvr_grp_cd: {},        // CDSC 모펀드
        lv_rctm_dl_psb_yn: {},          // 매입가능여부

        lv_asso_clsf_cd: {},            // 협회표준코드
        lv_bfcer_fnd_nm: {},            // 펀드명

        lv_bfcer_fnd_clct_sc_cd: {},    // 수익증권펀드모집구분코드?


        // 조회결과
        lv_fundSearchList: [],
        lv_fundShowList: [],

        lv_isFund: null,
        lv_page: {
          rowsPerPage: 10,
          currentPage: 1,
          totalPages: 0,
          totalItems: 0,
        },

        /***********************************************************************************
         * F10570175 : 펀드목록조회
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

        lv_isMain: false, // 메인 / 팝업 구분자
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      if(!FSCommUtil.gfn_isNull(this.$route.params.isMain)){
        this.lv_isMain = this.$route.params.isMain        
        this.fn_Init()        

        // 스크롤 초기화
        setTimeout(() => {
          this.$refs.tableList.scrollLeft = 0
        }, 100)

        // 자동 조회
        this.fn_FundListSearch()
      }
      this.fn_SetCommCode()      
    },
    mounted() {      
      this.$bizUtil.insSrnLog('MSPFS310P')
    },

    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.lv_isMain = false
        this.fn_Init()
        this.modal.open()

        // 스크롤 초기화
        setTimeout(() => {
          this.$refs.tableList.scrollLeft = 0
        }, 100)

        // 자동 조회
        this.fn_FundListSearch()
      },
      fn_Close() {
        this.modal.close()
        this.closeDropdown()
      },
      closeDropdown() {
        this.$refs.dropdown1.close()
      },
      fn_Confirm() {
        let t_popupObj = {}

        // 펀드목록 list 선택 체크
        if( FSCommUtil.gfn_isNull(this.lv_isFund) ) {
          t_popupObj.confirm = false
          t_popupObj.content = '펀드를 선택하여 주십시오.'
          this.fn_AlertPopup(0, t_popupObj)
          return
        }

        this.$emit('fs-popup-callback', 1, this.lv_fundShowList[this.lv_isFund - 1])
        this.modal.close()
      },


      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'105', 'dayChkYn':'N'},
          {'cId':'107', 'dayChkYn':'N'},
          {'cId':'120', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },
      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult

        // 펀드유형 코드 재설정
        //this.lv_commCode.code107.unshift({value: '', text: '전체'})
        
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS310P] fn_Init...')

        // 검색조건
        this.lv_bfcer_fnd_stat_cd         = FSCommUtil.gfn_dataSet(0, '1')      // 펀드상태코드
        this.lv_fund_coll_yn              = FSCommUtil.gfn_dataSet(0, 'ALL')    // 모집식펀드

        // this.lv_fnd_ivt_obj_typ_cd        = FSCommUtil.gfn_dataSet(0, '1')       // 펀드유형
        this.lv_fnd_ivt_obj_typ_cd        = FSCommUtil.gfn_dataSet(0, '1')       // 펀드유형
        this.lv_sale_co_mov_psb_yn        = FSCommUtil.gfn_dataSet(0, 'ALL')    // 판매사이동가능여부

        this.lv_ksd_fnd_cd                = FSCommUtil.gfn_dataSet(0, '')       // KRZ 펀드코드
        this.lv_new_dl_psb_yn             = FSCommUtil.gfn_dataSet(0, 'Y')    // 신규가능여부

        this.lv_cdsc_cnvr_grp_cd          = FSCommUtil.gfn_dataSet(0, '')       // CDSC 모펀드
        this.lv_rctm_dl_psb_yn            = FSCommUtil.gfn_dataSet(0, 'Y')    // 매입가능여부

        this.lv_asso_clsf_cd              = FSCommUtil.gfn_dataSet(0, '')       // 협회표준코드
        this.lv_bfcer_fnd_nm              = FSCommUtil.gfn_dataSet(0, '')       // 펀드명


        // 페이징 초기화
        this.fn_PageClear()
      },


      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        if( !FSCommUtil.gfn_isNull(pPopupObj) ) {
          // 공통 얼럿 팝업 오브젝트 초기화
          FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
        }
        this.$refs.alertPopup.fn_Open()
      },


      /******************************************************************************
       * Function명 : fn_FundListSearch, fn_FundListSearchResult
       * 설명       : 펀드목록조회
       ******************************************************************************/
      fn_FundListSearch() {
        console.log('fn_FundListSearch.....')
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570175_S'
        this.eaiCommObj.params = {
          bfcer_fnd_stat_cd      : this.lv_bfcer_fnd_stat_cd.value,        // 펀드상태코드
          fund_coll_yn           : this.lv_fund_coll_yn.value,             // 모집식펀드

          fnd_ivt_obj_typ_cd     : this.lv_fnd_ivt_obj_typ_cd.value,       // 펀드유형
          sale_co_mov_psb_yn     : this.lv_sale_co_mov_psb_yn.value,       // 판매사이동가능여부

          ksd_fnd_cd             : this.lv_ksd_fnd_cd.value,               // KRZ 펀드코드
          new_dl_psb_yn          : this.lv_new_dl_psb_yn.value,            // 신규가능여부

          cdsc_cnvr_grp_cd       : this.lv_cdsc_cnvr_grp_cd.value,         // CDSC 모펀드
          rctm_dl_psb_yn         : this.lv_rctm_dl_psb_yn.value,           // 매입가능여부

          asso_clsf_cd           : this.lv_asso_clsf_cd.value,             // 협회표준코드
          bfcer_fnd_nm           : this.lv_bfcer_fnd_nm.value,             // 펀드명

          depo_fund_cd           : '100000',                                // 예수금펀드코드
          bfcer_fnd_clct_sc_cd   : '',
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_FundListSearchResult)
      },

      fn_FundListSearchResult(pResultData) {
        console.log('fn_FundListSearchResult.....')

        let t_popupObj = {}
        let t_data = pResultData.data.sy000100_O_00VO

        // 페이징 초기화
        this.fn_PageClear()


        if( FSCommUtil.gfn_length(t_data) > 0 ) {

          t_data.forEach((item1) => {

            // 펀드등급 치환
            if( FSCommUtil.gfn_isNull(item1.fnd_grd_nm) ) {
              item1.fnd_grd_nm = '전체'
            }

            // 결산일 치환
            item1.nxtm_stact_ymd = FSCommUtil.gfn_dateReplace(1, item1.nxtm_stact_ymd)

            // 펀드구분 치환
            this.lv_commCode.code105.forEach((item2) => {
              if(item1.fnd_ivt_typ_cd == item2.value ) {
                item1.fnd_ivt_typ_cd = item2.text
              }
            })
          })

          this.lv_fundSearchList = t_data

          this.lv_page.totalPages = t_data.length / 10
          this.lv_page.totalItems = t_data.length

          this.fn_PageChange(1)

        } else {
          t_popupObj = {content: '입력하신 조건에 자료가 존재하지 않습니다.'}
          this.fn_AlertPopup(0, t_popupObj)

        }

      },


      /******************************************************************************
       * Function명 : fn_PageClear
       * 설명       : 페이징 초기화
       ******************************************************************************/
      fn_PageClear() {
        console.log('fn_PageClear.....')

        this.lv_fundSearchList            = []
        this.lv_fundShowList              = []
        this.lv_isFund                    = null

        this.lv_page.rowsPerPage          = 10
        this.lv_page.currentPage          = 1
        this.lv_page.totalPages           = 0
        this.lv_page.totalItems           = 0
      },


      /******************************************************************************
       * Function명 : fn_PageChange
       * 설명       : 페이징 처리
       ******************************************************************************/
      fn_PageChange(pageNum) {
        console.log('fn_PageChange.....')

        this.lv_isFund = null
        this.lv_fundShowList = []
        this.lv_page.currentPage = pageNum

        let strNum = (pageNum - 1) * this.lv_page.rowsPerPage
        let endNum = pageNum * this.lv_page.rowsPerPage

        for(let i=strNum; i<endNum; i++) {
          if( this.lv_fundSearchList[i] ) {
            this.lv_fundShowList.push(this.lv_fundSearchList[i])
          }
        }
      },

    }
  }
</script>
<style scoped>
</style>